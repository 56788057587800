import '../css/app.scss';

const $ = require('jquery');

require('bootstrap');
require('select2');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();

    $("#save-campaign").submit(function(e) {

        e.preventDefault();

        var $form = $(this);
        var url = $form.attr('action');
        var method = $form.attr('method');

        $('#save-campaign .message').text('');

        $.ajax({
            type: method,
            url: url,
            data: $form.serialize(),
            dataType: 'json',
            success: function(data){
                if(data['success']){
                    $('#save-campaign .success-message').text(data['message']);
                    if($form.data('reload') === 'Y'){
                        window.location.reload();
                    }
                }else{
                    $('#save-campaign .error-message').text(data['message']);
                }
            },
            error: function (data) {
                $('#save-campaign .error-message').text(data.responseJSON['message']);
            }
        });
    });

    $("#save-campaign-settings-button").click(function(e) {

        e.preventDefault();

        var form = $('#save-campaign-settings');
        var url = form.attr('action');
        var method = form.attr('method');

        $('#save-campaign-settings .message').text('');

        $.ajax({
            type: method,
            url: url,
            data: form.serialize(),
            dataType: 'json',
            success: function(data){
                if(data['success']){
                    $('#save-campaign-settings .success-message').text(data['message']);
                }else{
                    $('#save-campaign-settings .error-message').text(data['message']);
                }
                window.scrollTo(0, 0);
            },
            error: function (data) {
                $('#save-campaign-settings .error-message').text(data.responseJSON['message']);
                window.scrollTo(0, 0);
            }
        });
    });

    $('.js-campaign-delete').click(function () {
        var confirmText = $(this).data('confirm');
        if(confirm(confirmText)){

            $.ajax({
                type: 'post',
                url: $(this).data('href'),
                dataType: 'json',
                success: function(data){
                    if(data['success']){
                        window.location.reload();
                    }else{
                        alert(data['message']);
                    }
                },
                error: function (data) {
                    alert(data.responseJSON['message']);
                }
            });
        }
    })

    if($('#campaign_settings_stores').length){
        $('#campaign_settings_stores').select2()
    }

    $('#generate-access-token').click(function (e) {
        e.preventDefault();

        var rand = function() {
            return Math.random().toString(36).substr(2);
        }
        var token = function() {
            return rand() + rand() + rand();
        };

        $('#generate-access-token').parent().find('[name="campaign_settings[accessToken]"]').val(token());
    })
});